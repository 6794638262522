export const transformarObjeto = (obj, userId) => {
    const resultado = [];
    const ids = new Set(); // Para armazenar os IDs únicos

    // Identificar todos os IDs únicos das chaves
    Object.keys(obj).forEach(key => {
        const match = key.match(/\d+$/); // Extrai o número do final da chave
        if (match) {
            ids.add(match[0]); // Adiciona o ID único ao conjunto
        }
    });

    // Para cada ID, cria um objeto com tarefa e observacao
    ids.forEach(id => {
        resultado.push({
            sys_user_id: userId,
            tarefa_id: parseInt(id, 10), // Converte o ID para número
            value: obj[`tarefa${id}`] || "", // Pega a tarefa correspondente
            observacao: obj[`observacao${id}`] || "" // Pega a observação correspondente
        });
    });

    return resultado;
};