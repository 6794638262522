import React, { useState, useEffect, useRef } from 'react';
import { Pager, Paging, DataGrid, Selection, Scrolling, HeaderFilter, Column } from 'devextreme-react/data-grid';
import { getDataSourceByPost } from '../../../api/dataSource';
import Main from '../../../components/main/Main';
import { FormPopup } from './Form.jsx';
import { useAuth } from '../../../contexts/auth';
import 'devextreme-react/text-area';

const URL = '/atividade/checklist/list';

// Função para formatar a data no formato: Terça-feira \n 24/09
const formatDayLabel = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number); // Extrai ano, mês e dia da string
    const date = new Date(year, month - 1, day); // Cria a data localmente (mês em Date começa em 0)

    const dayOfWeek = new Intl.DateTimeFormat('pt-BR', { weekday: 'long' }).format(date); // Nome completo do dia
    const formattedDate = new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit' }).format(date); // Formato DD/MM

    return `${dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)}\n${formattedDate}`; // Formatação com quebra de linha
};

const Legenda = () => {
    return (
        <div>
            <div className="ds-flex flex-column">
                <div className="p-0 fw-bold">Legenda</div>
                <div className="p-0">
                    <span className="me-3"><i className="text-danger  me-1 fas fa-times fw-bold"></i>Não realizado</span>
                    <span className="me-3"><i className="text-success me-1 fas fa-check fw-bold"></i>Realizado</span>
                    <span className="me-3"><i className="text-muted   me-1 fas fa-minus"></i>Dispensado</span>
                    <span className="    "><i className="text-warning me-1 fas fa-exclamation fw-bold"></i>Atenção</span>
                </div>
            </div>
        </div>
    );
};

const CheckList = ({ privileges }) => {
    const [dataRows, setDataRows] = useState([]);
    const [days, setDays] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false); // Controle de visibilidade do popup
    const { user } = useAuth();
    const gridRef = useRef(null); // Usando useRef para referenciar o DataGrid

    const loadData = async () => {
        try {
            const response = await getDataSourceByPost(URL, { userId: user.id });

            // console.log(response.data)
            const transformedData = transformData(response.data);

            setDataRows(transformedData.rows);
            setDays(transformedData.days);

        } catch (error) {
            console.error('Error loading checklist data:', error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    // Transforma os dados agrupando por tarefa e criando colunas dinamicamente por dia
    const transformData = (data) => {
        const uniqueDays = [...new Set(data.map(item => item.day))]; // Extrai os dias únicos
        const tarefasMap = {};

        // Agrupa as tarefas por nome
        data.forEach(item => {
            if (!tarefasMap[item.tarefa_nome]) {
                tarefasMap[item.tarefa_nome] = { tarefa_nome: item.tarefa_nome };
            }
            tarefasMap[item.tarefa_nome][item.day] = item.tarefa_value;
        });

        const rows = Object.values(tarefasMap); // Converte o map para uma lista de tarefas
        return { rows, days: uniqueDays };
    };

    return (
        <Main title="/ Atividade / Check List" privileges={privileges}>
            {/* Botão que move o scroll para o final */}
            <button
                onClick={() => {
                    const gridInstance = gridRef.current?.instance; // Verifica se a instância está disponível
                    if (gridInstance) {
                        const scrollable = gridInstance.getScrollable();
                        if (scrollable) {
                            scrollable.scrollTo({ left: scrollable.scrollWidth }); // Move o scroll horizontal para o final
                        } else {
                            console.error("Scrollable not found");
                        }
                    } else {
                        console.error("Grid instance not found");
                    }
                }}
            >
                Send to end
            </button>

            <DataGrid
                ref={gridRef}
                id="grid"
                showBorders={true}
                dataSource={dataRows}
                repaintChangesOnly={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                width="100%"
                onContentReady={() => {
                    if (gridRef.current) {
                        setTimeout(() => {
                            const gridInstance = gridRef.current.instance;
                            const scrollable = gridInstance.getScrollable();
                            if (scrollable) {
                                scrollable.scrollTo({ left: scrollable.scrollWidth }); // Scroll para o final
                            }
                        }, 100); // Pequeno atraso para garantir que o layout esteja completo
                    }
                }}
            >
                {/* Coluna fixa para o nome da tarefa */}
                <Column dataField="tarefa_nome" caption="Tarefa" alignment="left" fixed={true}
                    allowSorting={false} allowFiltering={false} allowReordering={false} allowGrouping={false} width="250"
                />

                {/* Colunas dinâmicas baseadas nos dias */}
                {days.map((day, index) => (
                    <Column
                        key={index}
                        dataField={day}
                        alignment="center"
                        headerCellRender={() => (
                            <div className="text-center text-break">
                                {formatDayLabel(day).split('\n').map((line, i) => (
                                    <div key={i}>{line}</div>
                                ))}
                            </div>
                        )}

                        cellRender={(cellData) => {
                            const tarefaValue = isNaN(parseInt(cellData.text, 10)) ? -1 : parseInt(cellData.text, 10);

                            const iconMap = {
                                '-9': <i className="text-muted fas fa-minus" title={tarefaValue}></i>, // Dispensado
                                '-1': <i className="text-danger p-0 fas fa-times fw-bold" title={tarefaValue}></i>, // Não realizado
                                '0': <i className="text-warning p-0 fas fa-exclamation fw-bold" title={tarefaValue}></i>, // Atenção
                            };

                            // Caso seja maior ou igual a 1, retorna o ícone de sucesso com o valor da tarefa
                            if (tarefaValue >= 1) {
                                return <i className="text-success p-0 fas fa-check fw-bold" title={tarefaValue}></i>; // Realizado
                            }

                            // Retorna o ícone baseado no mapeamento, ou null se não houver correspondência
                            return iconMap[tarefaValue] || null;
                        }}


                        allowSorting={false}
                        allowFiltering={false}
                        allowReordering={false}
                        allowGrouping={false}
                        width="100"
                    />
                ))}
                <Paging defaultPageSize={500} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
                <HeaderFilter visible={true} />
                <Scrolling mode="standard" useNative={true} showScrollbar="always" />
                <Selection mode="single" />
            </DataGrid>

            <div className="d-flex mt-3">
                <button
                    onClick={() => setPopupVisible(true)}
                    type="button" className="btn btn-primary btn-sm align-self-center me-3">Check List Diário
                </button>
                <Legenda />
            </div>

            <FormPopup
                visible={popupVisible}
                onHiding={async () => {
                    await loadData();
                    setPopupVisible(false);
                }}
            />
        </Main>
    );
};

export default CheckList;
