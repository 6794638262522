import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/auth'
import './Header.css'

export default function (props) {

    const { user, signOut } = useAuth()
    const [themeCSS, setThemeCSS] = useState('-generic.light')

    const setDefaultTheme = (isOnLoad) => {

        const themeStorage = localStorage.getItem('theme') || "generic.light"
        if (themeStorage == 'generic.light') setThemeCSS('-light')
        if (themeStorage == 'generic.dark') setThemeCSS('-dark')
        localStorage.setItem('theme', themeStorage)

        if (!isOnLoad) {
            if (themeStorage == 'generic.light') {
                localStorage.setItem('theme', 'generic.dark')
                setThemeCSS('-dark')
            }
            if (themeStorage == 'generic.dark') {
                localStorage.setItem('theme', 'generic.light')
                setThemeCSS('-light')
            }
            window.location.reload()
        }
    }

    useEffect(() => {
        setDefaultTheme(true)

    }, [])


    const MenuComercial = () => {
        var hasAccess = false
        var menus = []

        user.header.forEach(element => {
            var access = element.value.substring(0, 9)
            if (access == 'comercial') hasAccess = true

            const v = element.value
            if (v == 'comercial_relacao_alunos_rematricula') menus.push(<SubMenuItem key={1} to="/comercial/relacao_alunos_rematricula" label="Relação De Alunos Sem Rematrícula" />)
            if (v == 'comercial_relacao_candidatos_inscritos') menus.push(<SubMenuItem key={2} to="/comercial/relacao_candidatos_inscritos" label="Relação De Candidatos Inscritos" />)
            if (v == 'comercial_leads') menus.push(<SubMenuItem key={3} to="/comercial/lead" label="Leads" />)
            if (v == 'comercial_comissoes') menus.push(<SubMenuItem key={4} to="/comercial/comissao" label="Comissões" />)

            if (v == 'comercial_atividades') menus.push(<SubMenuItem key={5} to="/pagina_em_desenvolvimento" label="Atividades" desenv={true} />)

            if (v == 'comercial_relatorio_comissao_rematricula') menus.push(<SubMenuItem key={6} to="/comercial/comercial_relatorio_comissao_rematricula" label="Relatório de comissões: Rematrícula" />)


        })
        if (hasAccess == false) return <></>
        return <SubMenu label="Comercial">{menus}</SubMenu>
    }

    const MenuAcademico = () => {
        var hasAccess = false
        var menus = []

        menus.push(<SubMenuItem key={1} target="_blank" to="/arquivos/calendario_academico_2302.pdf" label="Calendário Acadêmico" />)

        user.header.forEach(element => {
            var access = element.value.substring(0, 9)
            if (access == 'academico') hasAccess = true

            const v = element.value
            if (v == 'academico_relacao_alunos_matriculados') menus.push(<SubMenuItem key={2} to="/academico/relacao_alunos_matriculados" label="Relação De Alunos Matriculados" />)

        })
        if (hasAccess == false) return <></>



        return <SubMenu label="Acadêmico">{menus}</SubMenu>
    }


    // <li className="nav-item">
    //                     <Link className="nav-link" to="/acessos">Acessos</Link>
    //                 </li>


    const MenuAcessos = () => {
        if (user.id != 1) return <></>

        return (
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Acessos</a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/acessos">Sistemas</Link>
                </ul>
            </li>
        )
    }


    const MenuCustomerSuccess = () => {
        var hasAccess = false
        var menus = []

        user.header.forEach(element => {
            var access = element.value.substring(0, 16)
            if (access == 'customer_success') hasAccess = true

            const v = element.value
            if (v == 'customer_success_acompanhamento') menus.push(<SubMenuItem key={1} to="/customer_success/acompanhamento" label="Acompanhamento" />)
        })

        if (hasAccess == false) return <></>
        return <SubMenu label="Pós Vendas/Customer Success">{menus}</SubMenu>
    }

    const MenuAtividade = () => {
        let hasAccess = false
        var menus = []
        // console.log(user)    

        user.header.forEach(element => {
            // var access = element.value.substring(0, 16)
            // if (access == 'atividade') hasAccess = true

            hasAccess = user.header.some(element => element.value.startsWith('atividade'));

            const v = element.value
            if (v == 'atividade_check_list') menus.push(<SubMenuItem key={1} to="/atividade/check_list" label="Check Lists" />)
        })

        if (hasAccess == false) return <></>
        return <SubMenu label="Atividade">{menus}</SubMenu>
    }


    const MenuEducaMais = () => {
        var hasAccess = false
        var menus = []

        user.header.forEach(element => {
            var access = element.value.substring(0, 9)
            if (access == 'educamais') hasAccess = true

            const v = element.value
            if (v == 'educamais_alunos') menus.push(<SubMenuItem key={1} to="/educamais/aluno" label="Alunos" />)
            if (v == 'educamais_financeiro') menus.push(<SubMenuItem key={2} to="/educamais/financeiro" label="Financeiro" />)

        })
        if (hasAccess == false) return <></>
        return <SubMenu label="EducaMais">{menus}</SubMenu>
    }




    const renderMenuAdmin = () => {
        if (user.id != 1) return <></>

        return (
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Admin</a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/admin/pendencias">Pendências</Link>
                    <Link className="dropdown-item" to="/admin/usuarios">Usuários</Link>
                </ul>
            </li>
        )
    }

    const renderMenuRoboSiaa = () => {
        if (user.id != 1) return <></>

        return (
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Robô SIAA</a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/robo_siaa/relacao_candidatos_inscritos">Relação De Candidatos Inscritos</Link>
                </ul>
            </li>
        )
    }



    const SubMenu = (props) => {
        return (
            <div className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{props.label}</a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {props.children}
                </ul>
            </div>
        )
    }


    const MenuItem = (props) => {
        return <Link className="nav-link" to={props.to}>{props.label}</Link>
    }

    const MenuItemExternalNewTab = (props) => {
        return <a className="dropdown-item" target="_blank" href={props.href}>{props.label}</a>
    }


    const SubMenuItem = (props) => {
        const Arrow = () => <i className="fas fa-caret-right me-1"></i>
        const Desenv = () => {
            if (props.desenv == true) return <i className="fas fa-hourglass-start fa-xs"></i>
            return <></>
        }
        return <Link target={props.target} className="dropdown-item" to={props.to}><Arrow />{props.label} <Desenv /> </Link>
    }

    return (

        <nav className="navbar navbar-expand-sm bg-dark navbar-dark" style={{ height: "10vh" }}>
            <a className="navbar-brand" href="#"><span><i className="ms-2 me-2 fas fa-graduation-cap" />Sibe Education</span></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="navbar-nav me-auto mb-2 mb-lg-0">
                    <MenuAtividade />
                    <MenuComercial />
                    <MenuAcademico />
                    <MenuCustomerSuccess />
                    <MenuAcessos />

                    {/* <MenuEducaMais /> */}





                    {/* <SubMenu label="Manuais">
                        <span className="dropdown-item fw-bold" >Comercial</span>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/kCPRS">A Prova de Tudo</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/hpwOS">Rematrícula</a>

                        <span className="dropdown-item fw-bold" >Pós Vendas/Customer Success</span>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/jvLN0">Aula Inaugural</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/ktAU5">Acesso webs aulas e cronogramas</a>

                        <a className="dropdown-item" target="_blank" href="https://drive.google.com/file/d/1fu0aPTqBAVDkTYyyaVcNCOhyfhlijZSJ/view">Manual do aluno</a>

                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/dyFS5">Manual do Blackboard/Localizar Disciplinas</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/ayBJZ">Acesso ao aplicativo blackboard</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/sK049">Realização de Provas</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/jDT19">Acesso a biblioteca</a>


                        <span className="dropdown-item fw-bold" >Acadêmico</span>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/puvEJ">Atividades Complementares</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/ilAQV">Conhecimentos Gerais</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/iotJS">Declaração de Matrícula</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/crsuw">Dispensa de Disciplinas</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/yIPSX">Emitir boleto</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/gklxF">Enviar mensagem ao tutor</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/hBQR1">Envio de documentos</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/AGLM3">Localizar disciplinas</a>
                        <a className="dropdown-item" target="_blank" href="http://encurtador.com.br/ARSWZ">Reset de senha</a>



                        <MenuItemExternalNewTab
                            href="https://docs.google.com/document/d/13WmFkhCpjORyXpahOVs9YmvEBbtfBnd9HBNW9snD8z0/edit?usp=sharing"
                            label="Transferência de Polo"
                        />

                    </SubMenu> */}

                    {renderMenuRoboSiaa()}
                    {renderMenuAdmin()}
                </div>

                <div className="navbar-nav ms-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="me-2 fas fa-user" />{user.full_name}</a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a onClick={() => { signOut() }} className="dropdown-item" href="#"><i className="me-2 fas fa-sign-out-alt" />Sair</a>
                        </ul>
                    </li>
                </div>

            </div>
        </nav >
    )
}
