import React, { useState, useEffect } from 'react';
import { getDataSourceByPost } from '../../../api/dataSource';
import 'devextreme-react/text-area';
import { Popup } from 'devextreme-react/popup';
import { useAuth } from '../../../contexts/auth';
import { transformarObjeto } from './utils';

const URLTarefas = '/atividade/checklist/tarefa/tarefas_do_dia';
const URLLoad = '/atividade/checklist/load'; // Nova rota para carregar os valores salvos
const URLSave = '/atividade/checklist/save';

export const FormPopup = props => {
    const [inputData, setInputData] = useState([]); // Para armazenar a lista de tarefas
    const [formData, setFormData] = useState({}); // Para armazenar os valores dos inputs
    const { user } = useAuth();
    const [isVisible, setIsVisible] = useState(props.visible); // Controle local da visibilidade

    useEffect(() => {
        if (props.visible) {
            setIsVisible(true); // Mantém a visibilidade local sincronizada com a prop
            loadTarefas();
        }
    }, [props.visible]);

    const loadTarefas = async () => {
        try {
            // Carrega a lista de tarefas
            const tarefasResponse = await getDataSourceByPost(URLTarefas, { userId: user.id });
           
            setInputData(tarefasResponse.data); // Define os dados de input (tarefas)

            // Carrega os valores
            const valoresResponse = await getDataSourceByPost(URLLoad, { sys_user_id: user.id });
           
            const valores = valoresResponse.data;

            // Inicializa o formData com os valores carregados
            const initialFormData = {};
            tarefasResponse.data.forEach(item => {
                const valorCorrespondente = valores.find(v => v.tarefa_id === item.id);

                if (valorCorrespondente) {
                    initialFormData[`tarefa${item.id}`] = valorCorrespondente.value || '';
                    initialFormData[`observacao${item.id}`] = valorCorrespondente.observacao || '';
                } else {
                    initialFormData[`tarefa${item.id}`] = '';
                    initialFormData[`observacao${item.id}`] = '';
                }
            });

            console.log('Initial Form Data:', initialFormData);
            setFormData(initialFormData);
        } catch (error) {
            console.error('Error loading tarefas data or values:', error);
        }
    };

    const handleHiding = () => {
        setIsVisible(false); // Atualiza o estado local para fechar o popup
        props.onHiding(); // Propaga o fechamento para o componente pai
    };

    return (
        <Popup
            visible={isVisible}
            onHiding={handleHiding} // Garante que o fechamento seja tratado corretamente
            title="Check List Diário"
        >
            <Form inputData={inputData} formData={formData} setFormData={setFormData} onHiding={handleHiding} />
        </Popup>
    );
};

const Form = ({ inputData, formData, setFormData, onHiding }) => {
    const { user } = useAuth();

    const handleChange = (e, field) => {
        const { name, value } = e.target;
        console.log('Input Change:', { name, value });
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const _formData = transformarObjeto(formData, user.id);
        await getDataSourceByPost(URLSave, _formData);

        onHiding(); // Fecha o popup após o salvamento
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="container">
                {inputData.map(field => (
                    <div key={field.id} className="row mb-0 mb-2">
                        <div className="col-12 px-0">
                            {field.nome}
                        </div>
                        <div className="col-4 px-0">
                            <input
                                type={field.value_type === 'number' ? 'number' : 'text'}
                                className="form-control form-control-sm"
                                id={`tarefa${field.id}`}
                                name={`tarefa${field.id}`}
                                placeholder={field.placeholder}
                                value={formData[`tarefa${field.id}`] || ''}
                                onChange={(e) => handleChange(e, field)}
                            />
                        </div>
                        <div className="col-8 ps-1 px-0">
                            <input
                                type={'text'}
                                className="form-control form-control-sm"
                                id={`observacao${field.id}`}
                                name={`observacao${field.id}`}
                                placeholder={'Observação'}
                                value={formData[`observacao${field.id}`] || ''}
                                onChange={(e) => handleChange(e, field)}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <button  
                type="submit"
                className="btn btn-primary btn-sm">
                Salvar
            </button>
        </form>
    );
};
