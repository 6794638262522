import React, { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { useAuth } from './contexts/auth';
import Home from './pages/home/home'
import CustomerSuccessList from './pages/customer_success/list'
import { RelacaoAlunosMatriculados } from './pages/academico/index'
import { RelacaoCandidatosIncritos, RelacaoAlunosRematricula } from './pages/comercial/index'
import { EducaMaisAluno, EducaMaisFinanceiro } from './pages/educamais/index'
import PaginaEmDesenvolvimento from './pages/pagina_em_desenvolvimento'
import Pendencias from './pages/admin/pendencias'
import Rematricula from './pages/comercial/comissao/Rematricula'
import { Lead, Atividade, LeadInclusaoLote } from './pages/comercial/index'
import Usuarios from './pages/admin/usuarios'
import OpenChatWhatsApp from './pages/OpenChatWhatsApp'
import Acessos from './pages/acessos/Acessos';

import RoboSiaaRelacaoCandidatosIncritos from './pages/robo_siaa/relacao_candidatos_incritos'
import CheckList from './pages/atividade/checklist/CheckList';

export default function () {
  const { user } = useAuth()

  const getPrivileges = (sys_user_access_key) => { return }

  function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }

  return (
    <React.Fragment>
      <Routes>
        <Route path='/home' element={<Home />} />

        <Route path='/openchatwhatsapp' element={<OpenChatWhatsApp />} />

        <Route path='/atividade/check_list' element={<CheckList />} />

        <Route path='/comercial/relacao_candidatos_inscritos' element={<RelacaoCandidatosIncritos privileges={getPrivileges('gestao_relacao_candidatos_inscritos')} />} />
        <Route path='/comercial/relacao_alunos_rematricula' element={<RelacaoAlunosRematricula privileges={getPrivileges('gestao_relacao_alunos_rematricula')} />} />
        <Route path='/comercial/lead' element={<Lead privileges={getPrivileges('comercial_leads')} />} />
        <Route path='/comercial/lead/inclusao_lote' element={<LeadInclusaoLote privileges={getPrivileges('comercial_leads_inclusao_lote')} />} />
        <Route path='/comercial/atividade' element={<Atividade privileges={getPrivileges('comercial_atividades')} />} />
        <Route path='/comercial/comissao' element={<Rematricula privileges={getPrivileges('comercial_comissao_rematricula')} />} />

        <Route path='/comercial/comercial_relatorio_comissao_rematricula' element={<Rematricula privileges={getPrivileges('comercial_relatorio_comissao_rematricula')} />} />
        <Route path='/academico/relacao_alunos_matriculados' element={<RelacaoAlunosMatriculados privileges={getPrivileges('gestao_relacao_alunos_matriculados')} />} />

        <Route path='/pagina_em_desenvolvimento' element={<PaginaEmDesenvolvimento />} />
        <Route path='/admin/usuarios' element={<Usuarios privileges={getPrivileges('usuarios')} />} />
        <Route path='/admin/pendencias' element={<Pendencias privileges={getPrivileges('usuarios')} />} />
        <Route path='/customer_success/list' element={<CustomerSuccessList privileges={getPrivileges('customer_success')} />} />
        <Route path='/customer_success/acompanhamento' element={<CustomerSuccessList privileges={getPrivileges('customer_success')} />} />

        <Route path='/educamais/aluno' element={<EducaMaisAluno privileges={getPrivileges('educamais_alunos')} />} />
        <Route path='/educamais/financeiro' element={<EducaMaisFinanceiro privileges={getPrivileges('educamais_financeiro')} />} />


        <Route path='/acessos' element={<Acessos privileges={getPrivileges('sistema_acessos')} />} />






        {/* Acessos */}

        <Route path='/robo_siaa/relacao_candidatos_inscritos' element={<RoboSiaaRelacaoCandidatosIncritos privileges={getPrivileges('null')} />} />




        <Route path="*" element={<Redirect to="/home" />} /> 
      </Routes>
    </React.Fragment>
  );
}